import { useMemo } from 'react'
import useBadges from 'api/hooks/useBadges'
import { BADGES } from './Badges.constants'
import type { BadgeType } from 'types/api'

export default function useMeritBadges({ name }: { name: string }) {
  const badgesQuery = useBadges({
    name
  })

  const badges = useMemo(() => {
    // badges we're showing in the UI
    const enabledBadges = ['twitter', 'bluesky', 'participation']

    // badges this NFD has
    const nfdBadges = enabledBadges.filter((badge) => {
      if (badge === 'participation') {
        return badgesQuery.data?.[badge]?.[0]?.online === 'true'
      }
      return badgesQuery.data?.[badge]?.[0]
    })

    if (!badgesQuery.data || nfdBadges.length === 0) {
      return []
    }

    return nfdBadges.map((badge) => {
      const type = Object.entries(BADGES).find(([, value]) => value.key === badge)?.[0] as BadgeType
      const rank = badgesQuery.data[badge][0].rank

      return {
        type,
        ...(rank && { rank: rank as unknown as number })
      }
    })
  }, [badgesQuery.data])

  return {
    badges,
    isLoading: badgesQuery.isInitialLoading,
    isError: badgesQuery.isError
  }
}
